import { API } from "../../../constans/Url";
import { apiClient } from "../../../helper";

const get_ReportNeracaStandard = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.NERACA.STANDARD, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}


const get_ReportNeracaPeriode = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.NERACA.PERBANDINGAN, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ReportNeracaMulti = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.NERACA.MULTI, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_ReportNeracaMulti,
  get_ReportNeracaPeriode,
  get_ReportNeracaStandard
}