<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex ml-3 mb-2 align-items-center">
          <div class="title mr-auto">
            <h4 class="title">Neraca Multi Periode</h4>
          </div>

          <!-- <button class="btn-add" @click="generatePdf">Export</button>
          <button
            class="btn-add"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            + Tambah
          </button> -->
        </div>
        <div class="card-table">
          <div class="d-flex mb-4 align-items-center justify-content-end">
            <button class="btn btn-save" :style="{ minWidth: '170px' }" @click="this.showModalSingleYear = true">
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.5082 2.125C12.3311 2.125 12.1469 2.19583 12.0124 2.33042L10.7161 3.62667L13.3724 6.28292L14.6686 4.98667C14.9449 4.71042 14.9449 4.26417 14.6686 3.98792L13.0111 2.33042C12.8694 2.18875 12.6924 2.125 12.5082 2.125ZM9.95819 6.38917L10.6099 7.04083L4.19236 13.4583H3.54069V12.8067L9.95819 6.38917ZM2.12402 12.2188L9.95819 4.38458L12.6144 7.04083L4.78027 14.875H2.12402V12.2188Z"
                  fill="#fff" />
              </svg>
              Ubah Parameter
            </button>
          </div>

          <iframe id="pdfFrame" v-if="!isLoading"></iframe>
          <div v-else :style="{
              height: '450px',
              background: '#8f8f8f',
              position: 'relative',
            }">
            <loading :style="{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }" :active="isLoading" :can-cancel="false" :on-cancel="onCancel" :is-full-page="fullPage" color="#68caf8"
              height="80" width="80"></loading>
          </div>
        </div>
      </section>
    </div>
  </div>
  <modalRangeMonth :onPage="true" :show="showModalSingleYear" :type="'profit_multi'" :isMerge="true" @hide="setHide()"
    @update="updateData($event)" :departement="optionDepartement" :isMulti="true" :isOther="true"
    :parameter="parameterReport"></modalRangeMonth>
</template>

<script>
  import SidebarDashboard from "../../../../components/Sidebar.vue";
  import NavbarDashboard from "../../../../components/Navbar.vue";
  import modalRangeMonth from "../../components/report/modalRangeMonth.vue";
  // import Select2 from "vue3-select2-component";
  import {
    jsPDF
  } from "jspdf";
  import "jspdf-autotable";
  import "../../../../assets/font/poppins-bold.js";
  import "../../../../assets/font/poppins-regular.js";
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import Loading from "vue3-loading-overlay";

  import $ from "jquery";
  import {
    get_ReportNeracaMulti
  } from "../../../../actions/report/neraca";
  import {
    cksClient
  } from "../../../../helper";
  import moment from "moment";
  import "moment/locale/id";
  import {
    get_ListDivisi
  } from "../../../../actions/master";
import { DATA } from '../../../../constans/Url';
  // import { NONE } from "apisauce";

  // import TableLite from 'vue3-table-lite'
  export default {
    name: "dataDepartement",
    components: {
      SidebarDashboard,
      NavbarDashboard,
      // Select2,
      Loading,
      modalRangeMonth,
    },
    data() {
      return {
        showModalSingleYear: false,
        fullPage: true,
        isLoading: true,
        thr: "thr",
        bonus: "bonus",
        gaji: "gaji",
        canvas: null,
        optionDepartement: [],
        dataEstimatedAccount: [],
        parameterReport: {
          id_divisi: this.$route.query.id_divisi,
          date_start: this.$route.query.date_start,
          date_end: this.$route.query.date_end,
          merge: this.$route.query.merge,
          isAll: this.$route.query.isAll,
          isMonth: this.$route.query.isMonth,
        },
        dataCompany: {
        nama: "",
        divisi: "",
        alamat: "",
        email: "",
        no_telp: "",
        logo: "",
      },
        dataReport: [],
        dataColumn: [],
        titleDate: "",
        titleDateCol: "",
        dateColumn: {},
        namaReport: "",
      };
    },

    mounted() {
      if (
        this.$route.query.isMonth === true ||
        this.$route.query.isMonth == "true"
      ) {
        var split = this.$route.query.date_start.split("-");
        this.parameterReport.date_start = {
          year: split[0] ? split[0] : "",
          month: split[1] ? parseInt(split[1]) - 1 : "",
        };

        var splitend = this.$route.query.date_end.split("-");
        this.parameterReport.date_end = {
          year: splitend[0] ? splitend[0] : "",
          month: splitend[1] ? parseInt(splitend[1]) - 1 : "",
        };
      }
      if (this.parameterReport.date_start && this.parameterReport.date_end) {
        this.getData();
      }
      // this.tooltip();
      // this.exportPdf();
    },

    created() {
      this.getDivisi();
    },

    methods: {
      format(date, format = "MMMM YYYY") {
        if (date && date.year && date.month >= 0) {
          var string = date.year + "-" + (date.month + 1) + "-01";
          return `${moment(string).format(format)}`;
        }
      },
      getData() {
        var data = {
          ...this.parameterReport
        };
        data.id_company = cksClient().get("_account").id_company;
        if (data.isMonth === true || data.isMonth == "true") {
          data.date_start = this.format(data.date_start, "YYYY-MM");
          data.date_end = this.format(data.date_end, "YYYY-MM");
        }
        this.isLoading = true;
        get_ReportNeracaMulti(
          data,
          async (res) => {
              const {
                kas,
                biaya_dimuka,
                aset_lancar,
                aset_tetap,
                aset_lainnya,
                aset_tetap_tidak_lancar,
                akumulasi_penyusutan,
                persediaan,
                piutang_usaha,
                piutang_lainnya,
                kewajiban_jangka_pendek,
                kewajiban_jangka_panjang,
                hutang_usaha,
                ekuitas,
                dateArr,
                divisi,
                company
              } = res;

              this.dataCompany = company;
          this.dataCompany.divisi = divisi;
              if (divisi) {
                this.namaReport = divisi;
              } else {
                this.namaReport = cksClient().get("_account").nama_company;
              }

              this.dataColumn = [{
                title: "Deskripsi",
                dataKey: "akun",
              }, ];
              for (const key in dateArr) {
                if (Object.hasOwnProperty.call(dateArr, key)) {
                  const element = dateArr[key];
                  this.dataColumn.push({
                    title: this.parameterReport.isMonth == "true" ||
                      this.parameterReport.isMonth == true ?
                      moment(element + "-01").format("MMMM YYYY") : element,
                    dataKey: element,
                  });
                  this.dateColumn[element] = 0;
                }
              }
              // this.dataColumn.push({
              //   title: "Total",
              //   dataKey: "nilai",
              // });

              this.dataReport = [];

              var data = [];
              data.push({
                akun: "ASET",
                padding: 0,
                nilai: "",
                bold: true,
              }, {
                akun: "ASET LANCAR",
                padding: 2,
                bold: true,
                nilai: "",
              }, {
                akun: "Kas dan Setara Kas",
                padding: 3,
                bold: true,
                nilai: "",
              });

              var jumlah_kas = 0;
              for (const key in kas) {
                if (Object.hasOwnProperty.call(kas, key)) {
                  var element = kas[key];
                  element.padding = 4 + parseInt(element.sub_level);
                  var nilai = 0;
                  for (const i in dateArr) {
                    if (Object.hasOwnProperty.call(dateArr, i)) {
                      const el = dateArr[i];
                      nilai += element[el] ? parseInt(element[el]) : 0;
                      element[el] = element[el] ? this.formatMoney(element[el]) : 0;
                    }
                  }
                  element.nilai = this.formatMoney(nilai);
                  if (element.sub_level === 1 || element.sub_level === "1") {
                    jumlah_kas += element.nilai ?
                      parseInt(element.nilai.toString().split(".").join("")) :
                      0;
                  }
                  if (
                    !divisi &&
                    this.parameterReport.isAll != "true" &&
                    this.parameterReport.isAll != true &&
                    (element.sub_level == "1" || element.sub_level == 1)
                  ) {
                    // element.color = "#FF3D3D";
                  }
                  data.push(element);
                }
              }
              var arryJmlKas = await this.countJumlahPendapatan(kas);
              data.push({
                akun: "Jumlah Kas dan Setara Kas",
                bold: true,
                padding: 3,
                nilai: this.formatMoney(parseInt(jumlah_kas)),
                ...arryJmlKas,
              }, {
                akun: "",
                padding: 0,
                nilai: "",
              }, {
                akun: "Biaya Dibayar Dimuka",
                padding: 3,
                bold: true,
                nilai: "",
              });

              var jumlah_biaya_dimuka = 0;
              for (const key in biaya_dimuka) {
                if (Object.hasOwnProperty.call(biaya_dimuka, key)) {
                  var elbiaya = biaya_dimuka[key];
                  elbiaya.padding = 4 + parseInt(elbiaya.sub_level);
                  var nilaibiaya = 0;
                  for (const i in dateArr) {
                    if (Object.hasOwnProperty.call(dateArr, i)) {
                      const el = dateArr[i];
                      nilaibiaya += elbiaya[el] ? parseInt(elbiaya[el]) : 0;
                      elbiaya[el] = elbiaya[el] ? this.formatMoney(elbiaya[el]) : 0;
                    }
                  }
                  if (elbiaya.sub_level === 1 || elbiaya.sub_level === "1") {
                    jumlah_biaya_dimuka += nilaibiaya ?
                      parseInt(nilaibiaya.toString().split(".").join("")) :
                      0;
                  }
                  elbiaya.nilai = this.formatMoney(nilaibiaya);
                  elbiaya.nilai = elbiaya.nilai ?
                    this.formatMoney(elbiaya.nilai) :
                    0;
                  if (
                    !divisi &&
                    this.parameterReport.isAll != "true" &&
                    this.parameterReport.isAll != true &&
                    (elbiaya.sub_level == "1" || elbiaya.sub_level == 1)
                  ) {
                    elbiaya.color = "#FF3D3D";
                  }
                  data.push(elbiaya);
                }
              }

              var arryJmlBiayadiMuka = await this.countJumlahPendapatan(
                biaya_dimuka
              );

              data.push({
                akun: "Jumlah Biaya Dibayar Dimuka",
                padding: 3,
                bold: true,
                nilai: this.formatMoney(parseInt(jumlah_biaya_dimuka)),
                ...arryJmlBiayadiMuka,
              }, {
                akun: "",
                padding: 0,
                nilai: "",
              }, {
                akun: "Piutang Usaha",
                bold: true,
                padding: 3,
                nilai: "",
              });

              var jumlah_piutang_usaha = 0;
              for (const key in piutang_usaha) {
                if (Object.hasOwnProperty.call(piutang_usaha, key)) {
                  var elbeban = piutang_usaha[key];
                  elbeban.padding = 4 + parseInt(elbeban.sub_level);
                  var nilaibeban = 0;
                  for (const i in dateArr) {
                    if (Object.hasOwnProperty.call(dateArr, i)) {
                      const el = dateArr[i];
                      nilaibeban += elbeban[el] ? parseInt(elbeban[el]) : 0;
                      elbeban[el] = elbeban[el] ? this.formatMoney(elbeban[el]) : 0;
                    }
                  }
                  if (elbeban.sub_level === 1 || elbeban.sub_level === "1") {
                    jumlah_piutang_usaha += nilaibeban ?
                      parseInt(nilaibeban.toString().split(".").join("")) :
                      0;
                  }
                  elbeban.nilai = this.formatMoney(nilaibeban);
                  elbeban.nilai = elbeban.nilai ?
                    this.formatMoney(elbeban.nilai) :
                    0;
                  if (
                    !divisi &&
                    this.parameterReport.isAll != "true" &&
                    this.parameterReport.isAll != true &&
                    (elbeban.sub_level == "1" || elbeban.sub_level == 1)
                  ) {
                    elbeban.color = "#FF3D3D";
                  }
                  data.push(elbeban);
                }
              }

              var arryJmlPiutangUsaha = await this.countJumlahPendapatan(
                piutang_usaha
              );

              data.push({
                akun: "Jumlah Piutang Usaha",
                padding: 3,
                bold: true,
                nilai: this.formatMoney(parseInt(jumlah_piutang_usaha)),
                ...arryJmlPiutangUsaha,
              }, {
                akun: "",
                padding: "",
                nilai: "",
              }, {
                akun: "Piutang Diluar Usaha",
                padding: 3,
                bold: true,
                nilai: "",
              });

              var jumlah_piutang_lainnya = 0;
              for (const key in piutang_lainnya) {
                if (Object.hasOwnProperty.call(piutang_lainnya, key)) {
                  var elpiutang = piutang_lainnya[key];
                  elpiutang.padding = 4 + parseInt(elpiutang.sub_level);
                  var nilaipiutang = 0;
                  for (const i in dateArr) {
                    if (Object.hasOwnProperty.call(dateArr, i)) {
                      const el = dateArr[i];
                      nilaipiutang += elpiutang[el] ? parseInt(elpiutang[el]) : 0;
                      elpiutang[el] = elpiutang[el] ?
                        this.formatMoney(elpiutang[el]) :
                        0;
                    }
                  }
                  if (elpiutang.sub_level === 1 || elpiutang.sub_level === "1") {
                    jumlah_piutang_lainnya += nilaipiutang ?
                      parseInt(nilaipiutang.toString().split(".").join("")) :
                      0;
                  }
                  elpiutang.nilai = this.formatMoney(nilaipiutang);
                  elpiutang.nilai = elpiutang.nilai ?
                    this.formatMoney(elpiutang.nilai) :
                    0;
                  if (
                    !divisi &&
                    this.parameterReport.isAll != "true" &&
                    this.parameterReport.isAll != true &&
                    (elpiutang.sub_level == "1" || elpiutang.sub_level == 1)
                  ) {
                    elpiutang.color = "#FF3D3D";
                  }
                  data.push(elpiutang);
                }
              }

              var arryJmlPiutang = await this.countJumlahPendapatan(
                piutang_lainnya
              );

              data.push({
                akun: "Jumlah Piutang Diluar Usaha",
                padding: 3,
                bold: true,
                nilai: this.formatMoney(parseInt(jumlah_piutang_lainnya)),
                ...arryJmlPiutang,
              }, {
                akun: "",
                padding: "",
                nilai: "",
              }, {
                akun: "Persediaan",
                padding: 3,
                bold: true,
                nilai: "",
              });

              var jumlah_persediaan = 0;
              for (const key in persediaan) {
                if (Object.hasOwnProperty.call(persediaan, key)) {
                  var elpers = persediaan[key];
                  elpers.padding = 4 + parseInt(elpers.sub_level);
                  var nilaiops = 0;
                  for (const i in dateArr) {
                    if (Object.hasOwnProperty.call(dateArr, i)) {
                      const el = dateArr[i];
                      nilaiops += elpers[el] ? parseInt(elpers[el]) : 0;
                      elpers[el] = elpers[el] ? this.formatMoney(elpers[el]) : 0;
                    }
                  }
                  if (elpers.sub_level === 1 || elpers.sub_level === "1") {
                    jumlah_persediaan += nilaiops;
                  }
                  elpers.nilai = this.formatMoney(nilaiops);
                  elpers.nilai = elpers.nilai ? this.formatMoney(elpers.nilai) : 0;
                  if (
                    !divisi &&
                    this.parameterReport.isAll != "true" &&
                    this.parameterReport.isAll != true &&
                    (elpers.sub_level == "1" || elpers.sub_level == 1)
                  ) {
                    elpers.color = "#FF3D3D";
                  }
                  data.push(elpers);
                }
              }

              var arrJumlahPersediaan = await this.countJumlahPendapatan(
                persediaan
              );

              data.push({
                akun: "Jumlah Persediaan",
                padding: 3,
                bold: true,
                nilai: this.formatMoney(parseInt(jumlah_persediaan)),
                ...arrJumlahPersediaan,
              }, {
                akun: "",
                padding: "",
                nilai: "",
              }, {
                akun: "Aset Lancar Lainnya",
                padding: 3,
                bold: true,
                nilai: "",
              });

              var jumlah_aset_lancar = 0;
              for (const key in aset_lancar) {
                if (Object.hasOwnProperty.call(aset_lancar, key)) {
                  var elpen = aset_lancar[key];
                  elpen.padding = 4 + parseInt(elpen.sub_level);
                  var nilaipen = 0;
                  for (const i in dateArr) {
                    if (Object.hasOwnProperty.call(dateArr, i)) {
                      const el = dateArr[i];
                      nilaipen += elpen[el] ? parseInt(elpen[el]) : 0;
                      elpen[el] = elpen[el] ? this.formatMoney(elpen[el]) : 0;
                    }
                  }
                  if (elpen.sub_level === 1 || elpen.sub_level === "1") {
                    jumlah_aset_lancar += nilaipen ?
                      parseInt(nilaipen.toString().split(".").join("")) :
                      0;
                  }
                  elpen.nilai = this.formatMoney(nilaipen);
                  elpen.nilai = elpen.nilai ? this.formatMoney(elpen.nilai) : 0;
                  if (
                    !divisi &&
                    this.parameterReport.isAll != "true" &&
                    this.parameterReport.isAll != true &&
                    (elpen.sub_level == "1" || elpen.sub_level == 1)
                  ) {
                    elpen.color = "#FF3D3D";
                  }
                  data.push(elpen);
                }
              }

              var arrJumlahLainya = await this.countJumlahPendapatan(aset_lancar);

              data.push({
                akun: "Jumlah Aset Lancar Lainnya",
                padding: 3,
                nilai: this.formatMoney(parseInt(jumlah_aset_lancar)),
                bold: true,
                ...arrJumlahLainya,
              }, {
                akun: "Jumlah Aset Lancar",
                padding: 2,
                bold: true,
                nilai: this.formatMoney(
                  parseInt(jumlah_kas) +
                  parseInt(jumlah_biaya_dimuka) +
                  parseInt(jumlah_piutang_usaha) +
                  parseInt(jumlah_piutang_lainnya) +
                  parseInt(jumlah_persediaan) +
                  parseInt(jumlah_aset_lancar)
                ),
                ...this.countSummaryProfit([
                  arryJmlKas,
                  arryJmlBiayadiMuka,
                  arrJumlahPersediaan,
                  arryJmlPiutangUsaha,
                  arryJmlPiutang,
                  arrJumlahLainya,
                ]),
              }, {
                akun: "",
                padding: "",
                nilai: "",
              }, {
                akun: "ASET TETAP Tidak Lancar",
                padding: 2,
                bold: true,
              });

              var jumlah_asettetap_tidaklancar = 0;
              for (const key in aset_tetap_tidak_lancar) {
                if (Object.hasOwnProperty.call(aset_tetap_tidak_lancar, key)) {
                  var elasett_tidaklancar = aset_tetap_tidak_lancar[key];

                  elasett_tidaklancar.padding = 3 + parseInt(elasett_tidaklancar.sub_level);
                  var nilaibebanlt = 0;
                  for (const i in dateArr) {
                    if (Object.hasOwnProperty.call(dateArr, i)) {
                      const el = dateArr[i];
                      nilaibebanlt += elasett_tidaklancar[el] ? parseInt(elasett_tidaklancar[el]) : 0;
                      elasett_tidaklancar[el] = elasett_tidaklancar[el] ? this.formatMoney(elasett_tidaklancar[el]) :
                        0;
                    }
                  }
                  if (elasett_tidaklancar.sub_level === 1 || elasett_tidaklancar.sub_level === "1") {
                    jumlah_asettetap_tidaklancar += nilaibebanlt ?
                      parseInt(nilaibebanlt.toString().split(".").join("")) :
                      0;
                  }
                  elasett_tidaklancar.nilai = this.formatMoney(nilaibebanl);
                  elasett_tidaklancar.nilai = elasett_tidaklancar.nilai ?
                    this.formatMoney(elasett_tidaklancar.nilai) :
                    0;
                  if (
                    !divisi &&
                    this.parameterReport.isAll != "true" &&
                    this.parameterReport.isAll != true &&
                    (elasett_tidaklancar.sub_level == "1" || elasett_tidaklancar.sub_level == 1)
                  ) {
                    elasett_tidaklancar.color = "#FF3D3D";
                  }
                  data.push(elasett_tidaklancar);
                }
              }

              var arrJmlAsetTetapTLancar = await this.countJumlahPendapatan(aset_tetap_tidak_lancar);


              data.push({
                akun: "Jumlah Aset Tetap Tidak Lancar",
                padding: 2,
                bold: true,
                nilai: this.formatMoney(parseInt(jumlah_asettetap_tidaklancar)),
                ...arrJmlAsetTetapTLancar,
              }, {
                akun: "",
                padding: "",
                nilai: "",
              }, {
                akun: "ASET TETAP",
                padding: 2,
                bold: true,
              });

              var jumlah_aset_tetap = 0;
              for (const key in aset_tetap) {
                if (Object.hasOwnProperty.call(aset_tetap, key)) {
                  var elasett = aset_tetap[key];

                  elasett.padding = 3 + parseInt(elasett.sub_level);
                  var nilaibebanl = 0;
                  for (const i in dateArr) {
                    if (Object.hasOwnProperty.call(dateArr, i)) {
                      const el = dateArr[i];
                      nilaibebanl += elasett[el] ? parseInt(elasett[el]) : 0;
                      elasett[el] = elasett[el] ? this.formatMoney(elasett[el]) : 0;
                    }
                  }
                  if (elasett.sub_level === 1 || elasett.sub_level === "1") {
                    jumlah_aset_tetap += nilaibebanl ?
                      parseInt(nilaibebanl.toString().split(".").join("")) :
                      0;
                  }
                  elasett.nilai = this.formatMoney(nilaibebanl);
                  elasett.nilai = elasett.nilai ?
                    this.formatMoney(elasett.nilai) :
                    0;
                  if (
                    !divisi &&
                    this.parameterReport.isAll != "true" &&
                    this.parameterReport.isAll != true &&
                    (elasett.sub_level == "1" || elasett.sub_level == 1)
                  ) {
                    elasett.color = "#FF3D3D";
                  }
                  data.push(elasett);
                }
              }

              var arrJmlAsetTetap = await this.countJumlahPendapatan(aset_tetap);

              data.push({
                akun: "Jumlah Aset Tetap",
                padding: 2,
                bold: true,
                nilai: this.formatMoney(parseInt(jumlah_aset_tetap)),
                ...arrJmlAsetTetap,
              }, {
                akun: "",
                padding: "",
                nilai: "",
              }, {
                akun: "ASET LAINNYA",
                padding: 2,
                bold: true,
              });

              var jumlah_aset_lainnya = 0;
              for (const key in aset_lainnya) {
                if (Object.hasOwnProperty.call(aset_lainnya, key)) {
                  var ellainnya = aset_lainnya[key];

                  ellainnya.padding = 3 + parseInt(ellainnya.sub_level);
                  var nilailainnya = 0;
                  for (const i in dateArr) {
                    if (Object.hasOwnProperty.call(dateArr, i)) {
                      const el = dateArr[i];
                      nilailainnya += ellainnya[el] ? parseInt(ellainnya[el]) : 0;
                      ellainnya[el] = ellainnya[el] ?
                        this.formatMoney(ellainnya[el]) :
                        0;
                    }
                  }
                  if (ellainnya.sub_level === 1 || ellainnya.sub_level === "1") {
                    jumlah_aset_lainnya += nilailainnya ?
                      parseInt(nilailainnya.toString().split(".").join("")) :
                      0;
                  }
                  ellainnya.nilai = this.formatMoney(nilailainnya);
                  ellainnya.nilai = ellainnya.nilai ?
                    this.formatMoney(ellainnya.nilai) :
                    0;
                  if (
                    !divisi &&
                    this.parameterReport.isAll != "true" &&
                    this.parameterReport.isAll != true &&
                    (ellainnya.sub_level == "1" || ellainnya.sub_level == 1)
                  ) {
                    ellainnya.color = "#FF3D3D";
                  }
                  data.push(ellainnya);
                }
              }

              var arrJmlAsetLainnya = await this.countJumlahPendapatan(
                aset_lainnya
              );

              data.push({
                akun: "Jumlah Aset Lainya",
                padding: 2,
                bold: true,
                nilai: this.formatMoney(jumlah_aset_lainnya),
                ...arrJmlAsetLainnya,
              }, {
                akun: "",
                padding: "",
                nilai: "",
              }, {
                akun: "Akumulasi Penyusutan",
                padding: 2,
                bold: true,
                nilai: "",
              });

              var jumlah_akumulasi_penyusutan = 0;
              for (const key in akumulasi_penyusutan) {
                if (Object.hasOwnProperty.call(akumulasi_penyusutan, key)) {
                  var ellpenyusutan = akumulasi_penyusutan[key];

                  ellpenyusutan.padding = 3 + parseInt(ellpenyusutan.sub_level);
                  var nilaipenyusutan = 0;
                  for (const i in dateArr) {
                    if (Object.hasOwnProperty.call(dateArr, i)) {
                      const el = dateArr[i];
                      nilaipenyusutan += ellpenyusutan[el] ?
                        parseInt(ellpenyusutan[el]) :
                        0;
                      ellpenyusutan[el] = ellpenyusutan[el] ?
                        this.formatMoney(ellpenyusutan[el]) :
                        0;
                    }
                  }
                  if (
                    ellpenyusutan.sub_level === 1 ||
                    ellpenyusutan.sub_level === "1"
                  ) {
                    jumlah_akumulasi_penyusutan += nilaipenyusutan ?
                      parseInt(nilaipenyusutan.toString().split(".").join("")) :
                      0;
                  }
                  ellpenyusutan.nilai = this.formatMoney(nilaipenyusutan);
                  ellpenyusutan.nilai = ellpenyusutan.nilai ?
                    this.formatMoney(ellpenyusutan.nilai) :
                    0;
                  if (
                    !divisi &&
                    this.parameterReport.isAll != "true" &&
                    this.parameterReport.isAll != true &&
                    (ellpenyusutan.sub_level == "1" || ellpenyusutan.sub_level == 1)
                  ) {
                    ellpenyusutan.color = "#FF3D3D";
                  }
                  data.push(ellpenyusutan);
                }
              }

              var arrJmlAkumulasi = await this.countJumlahPendapatan(
                akumulasi_penyusutan
              );

              data.push({
                akun: "Jumlah Akumulasi Penyusutan",
                padding: 2,
                bold: true,
                nilai: this.formatMoney(jumlah_akumulasi_penyusutan),
                ...arrJmlAkumulasi,
              }, {
                akun: "JUMLAH ASET",
                padding: 0,
                bold: true,
                nilai: this.formatMoney(
                  this.sumArr([
                    jumlah_kas,
                    jumlah_biaya_dimuka,
                    jumlah_piutang_usaha,
                    jumlah_persediaan,
                    jumlah_aset_lancar,
                    jumlah_asettetap_tidaklancar,
                    jumlah_aset_tetap,
                    jumlah_aset_lainnya,
                    jumlah_piutang_lainnya,
                  ]) - jumlah_akumulasi_penyusutan
                ),
                ...this.countSummaryProfit([
                  arryJmlKas,
                  arryJmlBiayadiMuka,
                  arrJumlahPersediaan,
                  arryJmlPiutangUsaha,
                  arrJmlAsetTetapTLancar,
                  arrJmlAsetTetap,
                  arrJmlAsetLainnya,
                  arrJumlahLainya,
                  arryJmlPiutang,
                ], [arrJmlAkumulasi]),
              }, {
                akun: "",
              }, {
                akun: "KEWAJIBAN DAN EKUITAS",
                bold: true,
              }, {
                akun: "KEWAJIBAN",
                bold: true,
                padding: 2,
              }, {
                akun: "KEWAJIBAN JANGKA PENDEK",
                bold: true,
                padding: 3,
              }, {
                akun: "Hutang Usaha",
                bold: true,
                padding: 4,
              });

              var jumlah_hutang_usaha = 0;
              for (const key in hutang_usaha) {
                if (Object.hasOwnProperty.call(hutang_usaha, key)) {
                  var ellhutang = hutang_usaha[key];

                  ellhutang.padding = 5 + parseInt(ellhutang.sub_level);
                  var nilaihutang = 0;
                  for (const i in dateArr) {
                    if (Object.hasOwnProperty.call(dateArr, i)) {
                      const el = dateArr[i];
                      nilaihutang += ellhutang[el] ? parseInt(ellhutang[el]) : 0;
                      ellhutang[el] = ellhutang[el] ?
                        this.formatMoney(ellhutang[el]) :
                        0;
                    }
                  }
                  if (ellhutang.sub_level === 1 || ellhutang.sub_level === "1") {
                    jumlah_hutang_usaha += nilaihutang ?
                      parseInt(nilaihutang.toString().split(".").join("")) :
                      0;
                  }
                  ellhutang.nilai = this.formatMoney(nilaihutang);
                  ellhutang.nilai = ellhutang.nilai ?
                    this.formatMoney(ellhutang.nilai) :
                    0;
                  if (
                    !divisi &&
                    this.parameterReport.isAll != "true" &&
                    this.parameterReport.isAll != true &&
                    (ellhutang.sub_level == "1" || ellhutang.sub_level == 1)
                  ) {
                    ellhutang.color = "#FF3D3D";
                  }
                  data.push(ellhutang);
                }
              }

              var arrJmlHutang = await this.countJumlahPendapatan(hutang_usaha);

              data.push({
                akun: "Jumlah Hutang Usaha",
                bold: true,
                padding: 4,
                nilai: this.formatMoney(jumlah_hutang_usaha),
                ...arrJmlHutang,
              }, {
                akun: "",
              }, {
                akun: "Kewajiban Jangka Pendek Lainnya",
                bold: true,
                padding: 4,
              });

              var jumlah_kewajiban_jangka_pendek = 0;
              for (const key in kewajiban_jangka_pendek) {
                if (Object.hasOwnProperty.call(kewajiban_jangka_pendek, key)) {
                  var elpende = kewajiban_jangka_pendek[key];

                  elpende.padding = 5 + parseInt(elpende.sub_level);
                  var nilaipendek = 0;
                  for (const i in dateArr) {
                    if (Object.hasOwnProperty.call(dateArr, i)) {
                      const el = dateArr[i];
                      nilaipendek += elpende[el] ? parseInt(elpende[el]) : 0;
                      elpende[el] = elpende[el] ? this.formatMoney(elpende[el]) : 0;
                    }
                  }
                  if (elpende.sub_level === 1 || elpende.sub_level === "1") {
                    jumlah_kewajiban_jangka_pendek += nilaipendek ?
                      parseInt(nilaipendek.toString().split(".").join("")) :
                      0;
                  }
                  elpende.nilai = this.formatMoney(nilaipendek);
                  elpende.nilai = elpende.nilai ?
                    this.formatMoney(elpende.nilai) :
                    0;
                  if (
                    !divisi &&
                    this.parameterReport.isAll != "true" &&
                    this.parameterReport.isAll != true &&
                    (elpende.sub_level == "1" || elpende.sub_level == 1)
                  ) {
                    elpende.color = "#FF3D3D";
                  }
                  data.push(elpende);
                }
              }

              var arrJmlPendek = await this.countJumlahPendapatan(
                kewajiban_jangka_pendek
              );

              data.push({
                akun: "Jumlah Kewajiban Jangka Pendek Lainnya",
                bold: true,
                padding: 4,
                nilai: this.formatMoney(jumlah_kewajiban_jangka_pendek),
                ...arrJmlPendek,
              }, {
                akun: "",
              }, {
                akun: "Jumlah Kewajiban Jangka Pendek",
                bold: true,
                padding: 3,
                nilai: this.formatMoney(
                  this.sumArr([
                    jumlah_hutang_usaha,
                    jumlah_kewajiban_jangka_pendek,
                  ])
                ),
                ...this.countSummaryProfit([arrJmlHutang, arrJmlPendek]),
              }, {
                akun: "",
              }, {
                akun: "KEWAJIBAN JANGKA PANJANG",
                bold: true,
                padding: 3,
              });

              var jumlah_kewajiban_jangka_panjang = 0;
              for (const key in kewajiban_jangka_panjang) {
                if (Object.hasOwnProperty.call(kewajiban_jangka_panjang, key)) {
                  var elpanjang = kewajiban_jangka_panjang[key];

                  elpanjang.padding = 5 + parseInt(elpanjang.sub_level);
                  var nilaipanjang = 0;
                  for (const i in dateArr) {
                    if (Object.hasOwnProperty.call(dateArr, i)) {
                      const el = dateArr[i];
                      nilaipanjang += elpanjang[el] ? parseInt(elpanjang[el]) : 0;
                      elpanjang[el] = elpanjang[el] ?
                        this.formatMoney(elpanjang[el]) :
                        0;
                    }
                  }
                  if (elpanjang.sub_level === 1 || elpanjang.sub_level === "1") {
                    jumlah_kewajiban_jangka_panjang += nilaipanjang ?
                      parseInt(nilaipanjang.toString().split(".").join("")) :
                      0;
                  }
                  elpanjang.nilai = this.formatMoney(nilaipanjang);
                  elpanjang.nilai = elpanjang.nilai ?
                    this.formatMoney(elpanjang.nilai) :
                    0;
                  if (
                    !divisi &&
                    this.parameterReport.isAll != "true" &&
                    this.parameterReport.isAll != true &&
                    (elpanjang.sub_level == "1" || elpanjang.sub_level == 1)
                  ) {
                    elpanjang.color = "#FF3D3D";
                  }
                  data.push(elpanjang);
                }
              }

              var arrJmlPanjang = await this.countJumlahPendapatan(
                kewajiban_jangka_panjang
              );

              data.push({
                akun: "Jumlah Kewajiban Jangka Panjang",
                padding: 3,
                bold: true,
                nilai: this.formatMoney(jumlah_kewajiban_jangka_panjang),
                ...arrJmlPanjang,
              }, {
                akun: "Jumlah Kewajiban",
                padding: 2,
                bold: true,
                nilai: this.formatMoney(
                  this.sumArr([
                    jumlah_kewajiban_jangka_panjang,
                    jumlah_kewajiban_jangka_pendek,
                    jumlah_hutang_usaha,
                  ])
                ),
                ...this.countSummaryProfit([
                  arrJmlHutang,
                  arrJmlPendek,
                  arrJmlPanjang,
                ]),
              }, {
                akun: "",
              }, {
                akun: "EKUITAS",
                padding: 2,
                bold: true,
                nilai: "",
              });

              var jumlah_ekuitas = 0;
              for (const key in ekuitas) {
                if (Object.hasOwnProperty.call(ekuitas, key)) {
                  var elekuitas = ekuitas[key];

                  elekuitas.padding = 5 + parseInt(elekuitas.sub_level);
                  var nilaiekuitas = 0;
                  for (const i in dateArr) {
                    if (Object.hasOwnProperty.call(dateArr, i)) {
                      const el = dateArr[i];
                      nilaiekuitas += elekuitas[el] ? parseInt(elekuitas[el]) : 0;
                      elekuitas[el] = elekuitas[el] ?
                        this.formatMoney(elekuitas[el]) :
                        0;
                    }
                  }
                  if (elekuitas.sub_level === 1 || elekuitas.sub_level === "1") {
                    jumlah_ekuitas += nilaiekuitas ?
                      parseInt(nilaiekuitas.toString().split(".").join("")) :
                      0;
                  }
                  elekuitas.nilai = this.formatMoney(nilaiekuitas);
                  elekuitas.nilai = elekuitas.nilai ?
                    this.formatMoney(elekuitas.nilai) :
                    0;
                  if (
                    !divisi &&
                    this.parameterReport.isAll != "true" &&
                    this.parameterReport.isAll != true &&
                    (elekuitas.sub_level == "1" || elekuitas.sub_level == 1)
                  ) {
                    elekuitas.color = "#FF3D3D";
                  }
                  data.push(elekuitas);
                }
              }

              var arrJmlEkuitas = await this.countJumlahPendapatan(ekuitas);

              data.push({
                akun: "Jumlah Ekuitas",
                bold: true,
                nilai: this.formatMoney(jumlah_ekuitas),
                padding: 2,
                ...arrJmlEkuitas,
              }, {
                akun: "JUMLAH KEWAJIBAN DAN EKUITAS",
                bold: true,
                nilai: this.formatMoney(
                  this.sumArr([
                    jumlah_kewajiban_jangka_pendek +
                    jumlah_kewajiban_jangka_panjang +
                    jumlah_hutang_usaha +
                    jumlah_ekuitas,
                  ])
                ),
                ...this.countSummaryProfit([
                  arrJmlHutang,
                  arrJmlPendek,
                  arrJmlEkuitas,
                ]),
              });

              this.dataReport = data;
              this.convertDate(dateArr[0], dateArr[dateArr.length - 1]);
              this.isLoading = false;
              this.generatePdf();
            },
            (err) => {
              console.log(err);
            }
        );
      },

      sumArr(arr) {
        var nilai = 0;
        arr.map((element) => {
          nilai += parseInt(element.toString().split(".").join(""));
        });
        return nilai;
      },

      summaryArray(obj, type = "") {
        var nilai = 0;
        var i = 0;
        for (const key in obj) {
          if (Object.hasOwnProperty.call(obj, key)) {
            const element = obj[key];
            if (type == "beban") {
              if (i == 0) {
                nilai = parseInt(element.toString().split(".").join(""));
              } else if (
                parseInt(element.toString().split(".").join("")) >= 0 &&
                nilai != 0
              ) {
                nilai -= parseInt(element.toString().split(".").join(""));
              } else {
                nilai += parseInt(element.toString().split(".").join(""));
              }
            } else {
              if (i == 0) {
                nilai = parseInt(element.toString().split(".").join(""));
              } else if (
                parseInt(element.toString().split(".").join("")) >= 0 &&
                nilai != 0
              ) {
                nilai -= parseInt(element.toString().split(".").join(""));
              } else {
                nilai += parseInt(element.toString().split(".").join(""));
              }
            }
            i++;
          }
        }
        return nilai;
      },

      countJumlahPendapatan(data) {
        var object = {
          ...this.dateColumn
        };

        for (const i in data) {
          const element = data[i];
          for (const key in element) {
            if (
              Object.hasOwnProperty.call(element, key) &&
              key != "nilai" &&
              key != "akun" &&
              key != "padding"
            ) {
              const el = element[key];
              if (element.sub_level === 1 || element.sub_level === "1") {
                object[key] += el ?
                  parseInt(el.toString().split(".").join("")) :
                  0;
              }
            }
          }
        }

        for (const key in object) {
          if (Object.hasOwnProperty.call(object, key)) {
            object[key] = object[key] ? this.formatMoney(object[key]) : 0;
          }
        }

        return object;
      },

      countSummaryProfit(obj, obj2 = []) {
        var object = {
          ...this.dateColumn
        };
        console.log(object, obj2)
        for (const i in object) {
          if (Object.hasOwnProperty.call(object, i)) {
            var arr = [];
            for (const key in obj) {
              if (Object.hasOwnProperty.call(obj, key)) {
                const element = obj[key][i];
                arr.push(element);
              }
              var total = this.sumArr(arr);
              for (const j in obj2) {
                if (Object.hasOwnProperty.call(obj2, j)) {
                  var element = obj2[j][i];
                  if (element) {
                    total -= parseInt(element.toString().split('.').join(''));
                  }
                }
              }
              object[i] = this.formatMoney(total);
            }
          }
        }
        return object;
      },

      setHide() {
        this.showModalSingleYear = false;
      },

      updateData(event) {
        this.parameterReport = event;
        this.getData();
      },

      getDivisi() {
        get_ListDivisi({
            id_company: cksClient().get("_account").id_company,
          },
          (res) => {
            this.optionDepartement = res.list;
          }
        );
      },
      convertDate(start, end) {
        if (
          this.parameterReport.isMonth == true ||
          this.parameterReport.isMonth == "true"
        ) {
          var startMonth = moment(start + "-01");
          var endMonth = moment(end + "-01");
          if (startMonth.format("YYYY") === endMonth.format("YYYY")) {
            this.titleDate = `Per ${startMonth.format(
            "MMMM"
          )} s/d ${endMonth.format("MMMM YYYY")}`;
          } else {
            this.titleDate = `Per ${startMonth.format(
            "MMMM YYYY"
          )} s/d ${endMonth.format("MMMM YYYY")}`;
          }
        } else {
          this.titleDate = `Per ${start} s/d ${end}`;
        }
      },
      formatMoney(a) {
        if (!a) {
          return 0;
        }
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },

      convertImgToBase64(url) {
      return new Promise((resolve) => {
        var image = new Image();
        image.setAttribute("crossOrigin", "anonymous"); //getting images from external domain

        image.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = this.naturalWidth;
          canvas.height = this.naturalHeight;

          //next three lines for white background in case png has a transparent background
          var ctx = canvas.getContext("2d");
          ctx.fillStyle = "#6BACCC"; /// set white fill style
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          canvas.getContext("2d").drawImage(this, 0, 0);

          resolve(canvas.toDataURL("image/jpeg"));
        };

        image.src = url;
      });
    },

    splitTextIntoTwoArrays(text) {
      // Step 1: Split the text into words using whitespace as the delimiter
      const wordsArray = text.split(/\s+/);

      // Step 2: Determine the size of each array
      const totalWords = wordsArray.length;
      const arraySize = Math.ceil(totalWords / 2);

      // Step 3: Divide the words into three arrays
      const firstArray = wordsArray.slice(0, arraySize);
      // const secondArray = wordsArray.slice(arraySize, arraySize * 2);
      const secondArray = wordsArray.slice(arraySize);

      return [firstArray, secondArray];
    },

      async generatePdf() {
        var tableData = {
          columns: this.dataColumn,
          rows: this.dataReport,
        };

        let height = 210;
        let width = 297;
        if (tableData.columns.length > 4) {
          let widthColumn = tableData.columns.length * 50 + 100;
          width = widthColumn;
        }
        const doc = new jsPDF("l", "mm", [height, width]);
        var header = "img/bg-header.png";
        doc.addImage(header, "png", width, -35, width, 2500 / 72, null, null, 180);
        var logo = await this.convertImgToBase64(
        DATA.COMPANY(this.dataCompany.logo)
      );
        doc.addImage(logo, "png", 13, 6, 23, 23, null, null, 0);

        doc.setFont("Poppins-Bold").setFontSize(15);
        doc.setTextColor(255, 255, 255);
        doc.text(40, 15, `${this.dataCompany.nama}`, {
          align: "left",
        });
        doc.setFont("Poppins-Regular").setFontSize(10);
        doc.setTextColor(255, 255, 255);
        doc.text(40, 22, `${this.dataCompany.divisi ? this.dataCompany.divisi :  this.dataCompany.email}`, {
          align: "left",
        });

        doc.setFontSize(10);
        doc.setFont("Poppins-Regular");
        doc.splitTextToSize(this.dataCompany.alamat,
        10
      );

          const [array1, array2] = this.splitTextIntoTwoArrays(this.dataCompany.alamat, 2)
        doc.text(width - 12, 10, array1.join(" "), {
          align: "right",
        });
        doc.text(width - 12, 16, array2.join(" "), {
          align: "right",
        });
        doc.text(width - 12, 22, this.dataCompany.no_telp, {
          align: "right",
        });
        doc.text(width - 12, 28, this.dataCompany.divisi ? this.dataCompany.email : '', {
          align: "right",
        });
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(13);
        // doc.text(
        //   this.namaReport,
        //   doc.internal.pageSize.getWidth() / 2,
        //   45,
        //   "center"
        // );
        doc.setFontSize(16);
        doc.text(
          `Neraca (Multi Periode)`,
          doc.internal.pageSize.getWidth() / 2,
          45, {
            align: "center",
          }
        );
        doc.setProperties({
          title: `Neraca ${this.namaReport} (Multi Periode)`,
        });
        doc.setFontSize(10);
        doc.text(this.titleDate, doc.internal.pageSize.getWidth() / 2, 53, {
          align: "center",
        });

        // doc.text(145, 27, "Per Tgl. xx Feb 20xx", "center");
        var blankTable = {
          columns: [{
            title: "",
            dataKey: "id",
          }, ],
          rows: [{
            id: ""
          }, ],
        };
        doc.autoTable(blankTable.columns, blankTable.rows, {
          margin: {
            top: 45,
            bottom: 80,
          },
          theme: "plain",
        });
        doc.autoTable(tableData.columns, tableData.rows, {
          showHead: "everyPage",

          didParseCell: function (data) {
            var padding = parseInt(data.row.raw.padding);
            if (data.column.index === 0 && parseInt(padding)) {
              data.cell.styles.cellPadding = {
                left: 3 * parseInt(padding)
              };
            }

            if (data.column.index === 0 && data.row.raw.bold) {
              data.cell.styles.fontStyle = "bold";
            }

            if (data.row.section === "head") {
              data.cell.styles.textColor = "#324C8F";
              // data.cell.styles.halign = "center";
            }
            if (data.column.dataKey !== "akun") {
              data.cell.styles.halign = "right";
            }

            if (data.row.raw.color) {
              data.cell.styles.textColor = data.row.raw.color;
            }
          },

          columnStyles: {
            0: {
              cellPadding: 1,
              cellWidth: 100
            },
            1: {
              halign: "right"
            },
            2: {
              halign: "right"
            },
            3: {
              halign: "right"
            },
          },
          margin: {
            top: 10,
            bottom: 60
          },
          theme: "plain",
        });

        // PAGE NUMBERING
        // Add Page number at bottom-right
        // Get the number of pages
        const pageCount = doc.internal.getNumberOfPages();
        // var width = doc.internal.pageSize.getWidth();
        // var height = doc.internal.pageSize.getHeight();

        // For each page, print the page number and the total pages
        for (var i = 1; i <= pageCount; i++) {
          // Go to page i
          doc.setPage(i);

          //Print Page 1 of 4 for example
          // var widthPage = doc.internal.pageSize.getWidth();
          // var heightPage = doc.internal.pageSize.getHeight();
          var img = new Image();
          img.src = "img/line.png";
          doc.addImage(
            img,
            "png",
            width - 20,
            165,
            width - 40,
            1 / 72,
            null,
            null,
            180
          );

          doc.text(
            `Finanta Accounting System Report`,
            doc.internal.pageSize.getWidth() / 2,
            180, {
              align: "center",
            }
          );
          // doc.text(
          //   "Page " + String(i) + " of " + String(pageCount),
          //   90 - 20,
          //   210 - 30,
          //   null,
          //   null,
          //   "right"
          // );

          if (tableData.columns.length > 4) {
            doc.text(
              "Page " + String(i) + " of " + String(pageCount),
              doc.internal.pageSize.width / 1.09,
              180, {
                align: "center",
              }
            );
          } else {
            doc.text(
              "Page " + String(i) + " of " + String(pageCount),
              300 - 20,
              210 - 30,
              null,
              null,
              "right"
            );
          }

          doc.text(
            `Tercetak pada ${moment().format("DD MMMM YYYY - HH:mm")}`,
            205 - 192,
            210 - 30,
            null,
            null,
            "left"
          );
        }

        setTimeout(() => {
          var iframe = document.getElementById("pdfFrame");
          iframe.src = doc.output("bloburi", {
            filename: `Neraca ${this.namaReport} (Multi Periode).pdf`,
          });
        }, 500);
      },

      tooltip() {
        $('[data-toggle="tooltip"]').tooltip();
      },

      detail(payroll) {
        const getPayroll = payroll;

        if (getPayroll == "bonus") {
          this.$router.push("/data-payroll/detail-bonus");
        }
        if (getPayroll == "gaji") {
          this.$router.push("/data-payroll/detail-gaji");
        }
        if (getPayroll == "thr") {
          this.$router.push("/data-payroll/detail-thr");
        }

        $('[data-toggle="tooltip"]').tooltip("hide");
      },
    },
  };
</script>

<style scoped>
  .card-table {
    margin-top: 0px !important;
  }

  .btn-gaji {
    background-color: #68caf8;
    color: #fff;
    padding: 7px 12px !important;
    font-weight: 400;
    border-radius: 5px;
    font-size: 15px;
    border: none;
  }

  .btn-bonus {
    background-color: #f8ce3a;
    color: #fff;
    padding: 7px 12px !important;
    font-weight: 400;
    border-radius: 5px;
    font-size: 15px;
    border: none;
  }

  .btn-thr {
    background-color: #ff5252;
    color: #fff;
    padding: 7px 12px !important;
    font-weight: 400;
    border-radius: 5px;
    font-size: 15px;
    border: none;
  }

  .dropdown-menu {
    background: transparent !important;
    box-shadow: none !important;
    width: 102px !important;
    min-width: unset;
  }

  .dropdown-item:nth-child(2) {
    margin-top: 6px;
  }

  .dropdown-item:nth-child(3) {
    margin-top: 6px;
  }

  iframe {
    z-index: 1000;
    width: 100%;
    height: 800px;
  }
</style>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>